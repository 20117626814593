.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.body{
  background-color: beige;
}

.App-link {
  color: #61dafb;
}

.banner{
  padding-left: 25px;
  padding-top: 25px;
}

.heroText{
  text-align: center;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.heroImg{
  display: contents;
}

.infoText{
  padding-top: 100px;
}

.footer{
  text-align: center;
}